import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';

import styles from './MenuItemDesc.module.scss';

const cx = classNames.bind(styles);

const MenuItemDesc = ({ item }) => {
  const { korName, engName, imageUri, price, desc } = item;

  return (
    <div className={cx('item')}>
      {/* 이미지 */}
      <img src={imageUri} alt={korName} width="100%" />

      {/* 내용 */}
      <div className={cx('info')}>
        <div className={cx('detail')}>
          <div className={cx('desc')}>
            {desc.split('\n').map(line => (
              <span>
                {line}
                <br />
              </span>
            ))}
          </div>
        </div>
        <div className={cx('title')}>
          <div className={cx('korName')}>{korName}</div>
          <div className={cx('engName')}>{engName}</div>
        </div>
        <div className={cx('price')}>{commaNumber(price)}</div>
      </div>
    </div>
  );
};

MenuItemDesc.propTypes = {
  item: PropTypes.object.isRequired,
};

export default MenuItemDesc;
