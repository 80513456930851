export default {
  cakes: [
    {
      id: '002',
      korName: '더블 스트로베리 쉬폰 케이크',
      engName: 'double strawberry chiffon',
      imageUri: require('../../assets/images/menu/bakery/cake/002.png'),
      price: 46000,
      desc:
        '생딸기와 생크림을 두 겹으로 쌓아 더욱 부드럽고 촉촉한 쉬폰 케이크\n(계절 과일 수급 상황에 따라 데코레이션 일부가 변동될 수 있습니다.)',
    },
    {
      id: '003',
      korName: '듀얼 카카오 자허 토르테',
      engName: 'dual cacao sacher torte',
      imageUri: require('../../assets/images/menu/bakery/cake/003.png'),
      price: 43000,
      desc: '살구잼을 레이어드 한 초콜릿 시트에 다크초콜릿을 더해 진한 초콜릿 풍미를 느낄 수 있는 케이크',
    },
    {
      id: '005',
      korName: '모카 쉬폰 케이크',
      engName: 'mocha chiffon cake',
      imageUri: require('../../assets/images/menu/bakery/cake/005.png'),
      price: 43000,
      desc: '촉촉한 시트 사이로 느껴지는 생크림과 커피향의 은은한 조화가 어울리는 케이크',
    },
    {
      id: '006',
      korName: '얼그레이 쉬폰 케이크',
      engName: 'earl grey chiffon cake',
      imageUri: require('../../assets/images/menu/bakery/cake/006.png'),
      price: 43000,
      desc: '은은한 홍차 향과 부드러운 생크림이 입안 가득 퍼지는 쉬폰 케이크',
    },
    {
      id: '007',
      korName: '테디베어 초코 쉬폰',
      engName: 'teddy bear choco chiffon',
      imageUri: require('../../assets/images/menu/bakery/cake/007.png'),
      price: 43000,
      desc: '촉촉하고 달콤한 초코 시트와 부드러운 생크림이 만나 더욱 풍미가 깊은 쉬폰 케이크',
    },
    {
      id: '013',
      korName: '발로나 트리플 쇼콜라',
      engName: 'valrhona triple chocolat',
      imageUri: require('../../assets/images/menu/bakery/cake/013.png'),
      price: 45000,
      desc: '다크, 밀크 초콜릿 무스와 바나나 화이트 초콜릿 무스가 레이어드된 쇼콜라 케이크',
    },
    {
      id: '008',
      korName: '뉴욕 치즈케이크',
      engName: 'newyork cheesecake',
      imageUri: require('../../assets/images/menu/bakery/cake/008.png'),
      price: 44000,
      desc: '크림치즈의 진한 풍미와 촉촉한 식감을 느낄 수 있는 뉴욕 스타일의 치즈케이크',
    },
    {
      id: '015',
      korName: '바스크 치즈케이크',
      engName: 'basque cheese cake',
      imageUri: require('../../assets/images/menu/bakery/cake/015.png'),
      price: 29000,
      desc: '스페인바스크 지역의 전통 치즈케이크로 높은 온도에서 구워내 묵직한 치즈의 향과 맛이 느껴지는 케이크',
    },
    {
      id: '018',
      korName: '아티제 티라미수',
      engName: 'artisée tiramisu',
      imageUri: require('../../assets/images/menu/bakery/cake/018.png'),
      price: 29000,
      desc:
        '부드러운 크림치즈와 진한 풍미의 아티제 바리스타 블렌드 에스프레소의 맛과 향을 담은 시트, 발로나 파우더로 마무리한 시그니처 티라미수 케이크',
    },
    {
      id: '011',
      korName: '에쉬레 롤 케이크',
      engName: 'echire roll cake',
      imageUri: require('../../assets/images/menu/bakery/cake/011.png'),
      price: 21000,
      desc: '폭신한 시트에 풍미 가득한 에쉬레 버터 크림을 더한 롤케이크',
    },
    {
      id: '004',
      korName: '스트로베리 화이트 롤',
      engName: 'strawberry white roll',
      imageUri: require('../../assets/images/menu/bakery/cake/004.png'),
      price: 23000,
      desc: '달콤한 생딸기와 생크림이 들어있는 부드러운 화이트 스폰지 케이크',
    },
    {
      id: '001',
      korName: '화이트 베어 초코 케이크',
      engName: 'white bear chocolate cake',
      imageUri: require('../../assets/images/menu/bakery/cake/001.png'),
      price: 22000,
      desc: '촉촉하고 부드러운 초코 제누아즈 시트에 달콤한 코코넛 파우더를 더한 베어 케이크',
    },
    {
      id: '009',
      korName: '초코베어 자허토르테',
      engName: 'choco bear sacher torte',
      imageUri: require('../../assets/images/menu/bakery/cake/009.png'),
      price: 26000,
      desc: '살구잼을 레이어드한 초콜릿 시트에 다크 초콜릿을 더한 베어 케이크',
    },
  ],
  breads: [
    {
      id: '003',
      korName: '바게트 치즈식빵',
      engName: 'baguette cheese bread',
      imageUri: require('../../assets/images/menu/bakery/bread/003.png'),
      price: 5000,
      desc: '고소한 바게트빵에 롤치즈가 가미되어 담백하고 부드러운 식빵',
    },
    {
      id: '008',
      korName: '스위트 몽블랑',
      engName: 'sweet montblanc',
      imageUri: require('../../assets/images/menu/bakery/bread/008.png'),
      price: 5500,
      desc: '벨기에 버터로 만든 데니쉬에 럼 시럽과 살구향 잼을 바른 달콤한 데니쉬',
    },
    {
      id: '009',
      korName: '발로나 초코 데니쉬',
      engName: 'VALRHONA choco danish',
      imageUri: require('../../assets/images/menu/bakery/bread/009.png'),
      price: 5500,
      desc: '벨기에 버터로 만든 데니쉬에 발로나 초콜릿을 넣어 만든 달콤한 데니쉬',
    },
    {
      id: '012',
      korName: '에쉬레 크림치즈 브리오슈',
      engName: 'Echire cream cheese brioche',
      imageUri: require('../../assets/images/menu/bakery/bread/012.png'),
      price: 4400,
      desc: '에쉬레 브리오슈에 크림치즈로 속을 채우고 코코아 파우더를 올린 브리오슈',
    },
  ],
  castellas: [
    {
      id: '001',
      korName: '허니 카스텔라',
      engName: 'honey castella',
      imageUri: require('../../assets/images/menu/bakery/castella/001.png'),
      price: 12000,
      desc: '국내산 최고급 벌꿀을 사용하여 만든 부드럽고 달콤한 카스텔라',
    },
    {
      id: '002',
      korName: '흑미 카스텔라',
      engName: 'black rice castella',
      imageUri: require('../../assets/images/menu/bakery/castella/002.png'),
      price: 12000,
      desc: '몸에 좋은 검은 쌀로 만든 부드러운 맛이 일품인 카스텔라',
    },
    {
      id: '003',
      korName: '녹차 카스텔라',
      engName: 'green tea castella',
      imageUri: require('../../assets/images/menu/bakery/castella/003.png'),
      price: 12000,
      desc: '최고급 녹차로 만들어 향긋한 녹차 향이 일품인 카스텔라',
    },
  ],
};
