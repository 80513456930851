import React from 'react';

import Bakery from 'components/Menu/Bakery';
import Jumbotron from 'components/misc/Jumbotron';
import TabBar from 'components/misc/TabBar';

const tabBarMenus = [
  { name: '베이커리', link: '/menu/bakery/' },
  { name: '음료', link: '/menu/beverage/' },
  { name: '디저트', link: '/menu/dessert/' },
  { name: '푸드', link: '/menu/food/' },
  { name: '상품', link: '/menu/retail/' },
];

const BakeryPage = () => (
  <div>
    <Jumbotron
      engTitle="Bakery"
      korTitle="베이커리"
      image={require('../../assets/images/banner/menu-bakery.jpg')}
      content={
        <span>
          아티제가 만든 모든 베이커리는
          <br />
          artisan(장인)이 만든 하나의 작품이며,
          <br />
          엄선된 최고의 재료만을 사용합니다.
        </span>
      }
    />
    <TabBar index={0} data={tabBarMenus} />
    <Bakery />
  </div>
);

export default BakeryPage;
